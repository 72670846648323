import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {DataIspaccService} from '../utils/data-services/dataIspacc.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import swal from 'sweetalert2';
import {SelectConcessionsDialogComponent} from '../select-concessions-dialog/select-concessions-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-gestion-communications-kidi',
  templateUrl: './gestion-communications-kidi.component.html',
  styleUrls: ['./gestion-communications-kidi.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionCommunicationsKidiComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['nom_concession', 'ville', 'rue', 'code_postal', 'tel', 'open'];
  expandedElement: any | null;
  unfilteredCommentData = {
    generalComments: [],
    concessionComments: [],
    allComments: [],
  };
  currentConcession = null;
  generalComments = [];
  concessionComments = [];
  allComments = new MatTableDataSource<any>();
  isOpenGeneral = false;
  isOpenPerso = false;
  isOpenAll = true;
  targetFilter = new FormControl();
  targets = [
    'KIDI',
    'PSMS',
    'PWHEELER'
  ];

  constructor(
    private dataConcessionService: DataConcessionService,
    private dataIspaccService: DataIspaccService,
    private formBuilder: FormBuilder,
    private route: Router,
    private appComponent: AppComponent,
    public dialog: MatDialog,
    private datePipe: DatePipe,
) { }

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.getData();
    this.targetFilter.setValue(this.targets);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getData(): void {
    this.appComponent.isLoading = true;
    this.dataConcessionService.getCommentsKidiGeneral().subscribe(res => {
      for (const communication of res.records) {
        communication.targetFr = communication.target == 'kidi' ? 'KIDI' : (communication.target == 'pilot-wheeler' ? 'PWHEELER' : 'PSMS');
      }
      console.log(res)
      this.generalComments = res.records;
      this.unfilteredCommentData.generalComments = res.records;
      this.dataConcessionService.getConcession().subscribe(data => {
        this.dataSource.data = data.records;
        this.getAllCommentsKidi();
        this.appComponent.isLoading = false;
      });
    });
  }
  onFilterSelectChange(): any {
    setTimeout(() =>{
      this.generalComments = this.unfilteredCommentData.generalComments.filter(x => this.targetFilter.value.includes(x.targetFr));
      this.concessionComments = this.unfilteredCommentData.concessionComments.filter(x => this.targetFilter.value.includes(x.targetFr));
      this.allComments.data = this.unfilteredCommentData.allComments.filter(x => this.targetFilter.value.includes(x.targetFr));
    }, 1);
  }
  onOpenRow(concessionId): void {
    if (this.expandedElement != null) {
      document.getElementById('chevron-' + concessionId).style.transform = 'rotate(180deg)';
      if (this.currentConcession != null) {
        document.getElementById('chevron-' + this.currentConcession).style.transform = 'rotate(0deg)';
      }
      this.currentConcession = concessionId;
      this.concessionComments = [];
      this.appComponent.isLoading = true;
      this.dataConcessionService.getCommentsKidiByConcession(concessionId).subscribe(res => {
        for (const communication of res.records) {
          communication.targetFr = communication.target == 'kidi' ? 'KIDI' : 'PSMS';
        }
        this.concessionComments = res.records;
        this.unfilteredCommentData.concessionComments = res.records;
        this.appComponent.isLoading = false;
      });
    } else {
      document.getElementById('chevron-' + concessionId).style.transform = 'rotate(0deg)';
      this.currentConcession = null;
    }
  }
  getConcessionName(concessionId): string {
    const name = this.dataSource.data.filter(x => x.concession_id == concessionId);
    if (name.length > 0)
      return name[0].nom_concession;
    return '<strong>Toutes</strong>';
}
  getAllCommentsKidi(): void {
    if (this.isOpenAll) {
      this.appComponent.isLoading = true;
      this.dataConcessionService.getAllCommentsKidi().subscribe(res => {
        for (const communication of res.records) {
          communication.concessionName = this.getConcessionName(communication.concession_id);
          communication.targetFr = communication.target == 'kidi' ? 'KIDI' : 'PSMS';
        }
        this.allComments.data = res.records;
        this.unfilteredCommentData.allComments = res.records;
        this.appComponent.isLoading = false;
      });
    }
  }
  onDuplicateComment(comment) {
    this.openDialog(comment);
  }
  onDeleteGeneralComment(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Vous êtes sur le point de supprimer une communication, êtes-vous sûr de vouloir continuer?',
      type: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataConcessionService.deleteCommentsKidi(id).subscribe(res => {
          this.dataConcessionService.getCommentsKidiGeneral().subscribe(res => {
            this.generalComments = res.records;
            this.getData();
          });
          swal.fire({
            title: 'Communication supprimée!',
            text: 'La communication a bien été supprimée.',
            type: 'success',
            confirmButtonText: 'Ok'
          });
        }, err => {
          swal.fire({
            title: 'Oops...!',
            text: 'Une erreur est surnevue lors de la suppression..',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  onClickGeneral() {
    this.isOpenGeneral = !this.isOpenGeneral;
  }
  onClickPerso() {
    this.isOpenPerso = !this.isOpenPerso;
  }
  onClickAll() {
    this.isOpenAll = !this.isOpenAll;
    this.getAllCommentsKidi();
  }
  openDialog(data): void {
    const dialogRef = this.dialog.open(SelectConcessionsDialogComponent, {
      width: '450px',
      // height: '80%',
      data,
    });

    dialogRef.afterClosed().subscribe(results => {
      if (results) {
        let error = '';
        for (const concession of results) {
          const body = {
            title: data.title,
            subject: data.subject,
            text: data.text,
            created_at: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            updated_at: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            is_active: data.is_active,
            concession_id: concession.item_id
          };
          this.dataConcessionService.postCommentsKidi(JSON.stringify(body)).subscribe(res => {
            this.getData();
            swal.fire({
              title: 'Communication enregistrée!',
              text: 'La communication a bien été enregistrée dans la/les concession(s).',
              type: 'success',
              confirmButtonText: 'Ok'
            });
          }, error1 => {
            error += concession.item_text + ', ';
          });
        }
        if (error.length > 0) {
          swal.fire({
            title: 'Oops...!',
            text: 'Une erreur est surnevue lors de la sauvegarde : ' + error,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      }
    });
  }
  applyFilter(event: Event) {
    this.appComponent.isLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    setTimeout(() => { this.appComponent.isLoading = false; });
  }
  applyFilterAll(event: Event) {
    this.appComponent.isLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.allComments.filter = filterValue.trim();
    setTimeout(() => { this.appComponent.isLoading = false; });
  }
}
